.container {
  font-family: 'Inter', sans-serif;
}
  
.orgIdContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #eee;
}

.orgIdLabelText {
  margin-right: 8px;
}

.orgIdValue {
  background-color: #e8f5e9;
  color: #00c853;
  font-weight: bold;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 4px;
}

.creditsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.creditsLabel {
  font-weight: 500;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
}

.creditsValue {
  font-weight: bold;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}