.dashboardRoot {
    display: flex;
    padding-top: 64px;
    min-height: 100vh;
}

.rightPanel {
    background-color: #fff;
    flex: 1;
}
.tokenDashboard {
    padding: 40px 32px;
}
