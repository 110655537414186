.dashboardRoot {
  display: flex;
  padding-top: 64px;
  min-height: 100vh;
}
.leftPanel {
  max-width: 400px;
  width: 400px;
  background-color: #eeeff1;
  position: relative;
  height: calc(100vh - 64px);
}
.rightPanel {
  background-color: #fff;
  flex: 1;
}
.mainDashboard {
  padding: 40px 32px;
}
.copyRight {
  position: absolute;
  bottom: 16px;
  color: #949ea7;
  font-size: 14px;
  left: 32px;
}
.leftMenu {
  background-color: #fff;
  max-width: 300px;
  border-radius: 8px;

  margin: 0 auto;
  margin-top: 40px;
}
.menuItem {
  display: flex;
  align-items: center;
  padding: 0 12px;
}
.menuLabel {
  padding: 12px 0;
  margin-left: 12px;
  font-weight: 500;
  flex: 1;
  border-bottom: 1px solid #eeeff1;
}
.logoutLabel {
  padding: 12px 0;
  margin-left: 12px;
  font-weight: 500;
  color: #949ea7;
}
.sidebarItem {
  display: flex;
  align-items:center;
  padding: 0 12px;
}
