.navBar {
  background-color: #1d2023 !important;
}
.navBarItems {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.mainDashboardButton {
  left: 250px;
}
.logo {
  height: 32px;
}
.menuItem {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.logoutLabel {
  margin-left: 8px;
}
