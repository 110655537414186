body {
  background-color: #eeeff1;
  font-family: 'Inter', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"',
    'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif', '"Apple Color Emoji"',
    '"Segoe UI Emoji"', '"Segoe UI Symbol"';
}
a {
  color: #01b075;
  text-decoration: none;
}
.icon-root {
  fill: none !important;
  stroke: #1d2023;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.mapboxgl-canvas {
  width: 100%;
}

.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.mapboxgl-ctrl-bottom-right {
  bottom: 42px;
  right: 8px;
}

.mapboxgl-ctrl-top-left {
  top: 40vh;
}
.map-and-drawer {
  display: flex;
  height: calc(100vh - 64px);
}
.map-and-controls-container {
  flex: 1;
  position: relative;
  /* height: 100vh; */
  left: -1px;
  top: -1px;
}
.flex {
  display: flex;
}
.mr-12 {
  margin-right: 12px !important;
}
.mr-16 {
  margin-right: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mt-16 {
  margin-top: 16px;
}
.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  position: relative;
  bottom: -86px;
}
.mapboxgl-ctrl-attrib-button {
  display: none;
}

#compare-container .mapboxgl-compare .compare-swiper-vertical,
#compare-container .mapboxgl-compare .compare-swiper-horizontal {
  background-color: #01b075;
}

@media (min-width: 600px) {
  .right-side-drawer {
    width: 0;
  }
  .right-side-drawer.open {
    width: 490px;
  }
}
@media (max-width: 600px) {
  .right-side-drawer {
    width: 'unset';
    max-height: 360;
    overflow-y: 'scroll';
  }
}
.country-icon {
  height: 20px;
  width: 20px;
  background-size: contain !important;
  image-rendering: -webkit-optimize-contrast;
}

.mr-2 {
  margin-right: 2px;
}
.ml-2 {
  margin-left: 2px;
}

.hubspot-chatflow {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000; /* Ensure it's above other content */
}

.mapboxgl-popup .mapboxgl-popup-tip {
  visibility: hidden;
}

.mapboxgl-popup .mapboxgl-popup-content {
  background: rgba(29, 32, 35, 0.8);
  border-radius: 6px;
  font-size: 14px;
  text-align: center;
  padding: 12px;
  color: #fff;
}

.mapboxgl-popup .mapboxgl-popup-content .warning {
  color: rgba(248, 74, 74, 1);
}
