.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.tokenLabelText{
    color: #1D2023;
    font-size: 28px;
    font-family: Inter;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.20px;
    word-wrap: break-word
}

.addTokenButton {
    padding: 8px 16px;
    color: white;
    font-size: 12px;
    font-family: Inter;
    font-weight: 700;
    line-height: 20px;
    word-wrap: break-word
}

.tableContainer {
    overflow-x: auto;
}

.dashboardTable {
    width: 100%;
    border-collapse: collapse;
}

.tableTitle th {
    text-align: left;
    padding: 12px;
    background-color: #f5f5f5;
    font-weight: bold;

    color: #949EA7;
    font-size: 12px;
    font-family: Inter;
    font-weight: 600;
    line-height: 20px;
    word-wrap: break-word
}

.tableItems tr:hover {
    background-color: #f5f5f5;
}

.tableItems td {
    padding: 16px 12px;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    color: #1D2023;
    font-size: 14px;
    font-family: Inter;
    font-weight: 700;
    line-height: 24px;
    word-wrap: break-word
}

.tableItems td:nth-child(2) {
    padding: 12px;
}

.typeCell {
    display: inline-block;
    background-color: #eee8fc;
    color: #9C7FEF;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
}

.tableItems td:nth-child(3),
.tableItems td:nth-child(4) {
    padding: 12px;
}

.dateCell {
    display: flex;
    flex-direction: column;
}

.statusCell {
    display: inline-block;
    background-color: #fdf3e8;
    color: #EA891D;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
}


.dateTop {
    color: #1D2023;
    font-size: 14px;
    font-family: Inter;
    font-weight: 700;
    line-height: 24px;
    word-wrap: break-word;
}

.dateBottom {
    color: #949EA7;
    font-size: 12px;
    font-family: Inter;
    font-weight: 500;
    line-height: 20px;
    word-wrap: break-word;
}
.tableItems td:last-child {
    color: #4caf50;
    font-weight: bold;
}

.usageCircularProgress {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}