.mainGrid,
.tableHeader {
  display: grid;
  grid-template-columns: 1fr 160px 160px 100px 120px;
  column-gap: 10px;
  row-gap: 10px;
  max-width: 1200px;
}
.tableHeader {
  font-size: 12px;
  height: 36px;
  align-items: center;
  color: #949ea7;
  border-bottom: 1px solid #eeeff1;
}

.headerText{
  color: #01B075;
  font-size: 16px;
  font-weight: 600;
}

.tableTitle{
  color: #949EA7;
  background-color: #f2f2f2;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word
}

.tableItems{
  color: #1D2023;
  font-size: 14px;
  font-family: Inter;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word
}

.requestTypeContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.bold {
  color: #1D2023;
  font-size: 14px;
  font-family: Inter;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word
}

.faded {
  color: #949EA7;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word  
}

.iconWrapper {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 4px;
  transform: translateY(-2px);
}

.statusCell {
  display: inline-block;
  /* background-color: #fdf3e8;
  color: #EA891D; */
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}

.expandIcon {
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
}

.mainGrid {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
}
.productColumn {
  grid-column: 1;
}
.requestTypeColumn {
  grid-column: 2;
}
.apiUsageColumn {
  grid-column: 3;
}
.priceColumn {
  grid-column: 4;
  text-align: right;
}
.costColumn {
  grid-column: 5;
  text-align: right;
}
.rowBorderBottom {
  border-bottom: 1px solid #eeeff1;
  grid-column: 1/6;
}
.tokenDisplay {
  border: 1px solid #d4d8dc;
  border-radius: 4px;
  display: flex;
  padding: 12px;
  font-weight: 600;
  margin: 12px 0;
  max-width: 1200px;
}
.worldIconContainer {
  background-color: #e5f7f1;
  border-radius: 4px;
  text-align: center;
  padding: 12px;
  line-height: 1;
  margin-right: 8px;
}
.tokenNumber {
  flex: 1;
}
.chevronIcon {
  stroke: #01b075;
  vertical-align: middle;
}
.infoPill {
  border-radius: 4px;
  color: #1D2023;
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
  line-height: 24px;
  padding: 14px;
  border: 1px solid #d4d8dc;
  display: inline-block;
}
.calendarInputRoot {
  width: 222px;
  margin: 0 8px;
}
.calendarInputRoot input {
  padding: 10px 10px;
}
.calendarInput {
  /* padding: 0 14px 0 0; */
}
.datePickerContainer {
  display: inline-block;
  border-radius: 4px;
  margin-left: 8px;
}
.tableOptions {
  margin-top: 20px;
  align-items: center;
  display: flex;
  justify-content: start;
  gap: 10px;
}


.tokenInfo {
  /* Style as needed based on your mockup */
  display: flex;
  align-items: center;
  margin-left: 16px; /* Space it out from the previous elements */
  min-width: 160px; /* Set a min-width for the dropdown */
  padding: 4px 8px; /* Adjust padding as needed */
  border-radius: 4px; /* Optional: if you want rounded corners */
}

.usageCircularProgress {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.dashboardTable {
  width: 100%;
  margin-top: 10px;;
  border-collapse: collapse;
  /* Other table styles */
}

.dashboardTable th, .dashboardTable td {
  text-align: left;
  padding: 8px; /* Adjust the padding as needed */
  /* Other table cell styles */
}

.dashboardTable tbody tr:nth-child(odd) {
  background-color: #fff; /* For zebra striping, if applicable */
}
