.dashboardRoot {
  display: flex;
  padding-top: 64px;
  min-height: 100vh;
}
.leftPanel {
  max-width: 400px;
  width: 400px;
  background-color: #eeeff1;
  position: relative;
  height: calc(100vh - 64px);
}
.rightPanel {
  position: relative;
  height: calc(100vh - 64px);
  /* margin-top: 10px; */
  padding: 0 64px 0 0;
  background-color: #fff;
  flex: 1;
}
.backToProjectsContainer {
  position: absolute;
  top: 16px;
  left: 16px;
  background-color: #fff;
  z-index: 2;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px;
}

.viewSubscriptions {
  position: relative;
  min-height: calc(100vh - 64px);
  /* margin-top: 10px; */
  margin: 1px;
  padding: 20px 10px 10px 32px;
  background-color: #fff;
  flex: 1;
}
.copyRight {
  position: absolute;
  bottom: 16px;
  color: #949ea7;
  font-size: 14px;
  left: 32px;
}
.leftMenu {
  background-color: #fff;
  max-width: 300px;
  margin: 0 auto;
  margin-top: 40px;
  border-radius: 4px; 
  padding: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}

.menuItem {
  display: flex; 
  align-items: center; 
  text-decoration: none; 
  color: #333333; 
  padding: 5px 0;
  border-bottom: '1px solid #eeeff1';
}

.menuLabel {
  padding: 8px 0;
  margin-left: 10px;
  font-weight: 500;
  font-size: 14px;
  font-family: Inter;
  flex: 1;
}
.logoutLabel {
  padding: 12px 0;
  margin-left: 12px;
  font-weight: 500;
  color: #949ea7;
}
.sidebarItem {
  display: flex;
  align-items: center;
  padding: 0 12px;
}
.pdrListTitle {
  display: flex;
  padding-bottom: 8px;
}
.pdrListTitle h5 {
  flex: 1;
}


.tokenSection {
  background-color: #fff;
  margin: 20px auto;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 80px; /* Adjusted to avoid overlap with copyright */
  left: 0;
  right: 0;
  max-width: 300px;
}

.tokenInfo,
.creditInfo {
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #eeeff1;
}

.tokenLabel,
.creditLabel {
  font-weight: 500;
  margin-right: 12px;
  color: #6f7b85;
}

.tokenValue,
.creditValue {
  font-weight: 700;
  color: #01b075;
  flex: 1;
  text-align: right;
}

.loader {
  text-align: center;
}

.topContainer {
  margin-bottom: 20px; /* Add margin at the bottom */
}

.bottomContainer {
  margin-top: 20px; /* Add margin at the top */
}

.menuItem.active {
  color: #01b075;
}

.arrow {
  margin-left: auto;
  color: #01b075;
}

.borderLink {
  position: relative;
}

.borderLink::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #eee;
}